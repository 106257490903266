import { Main, PropertiesWrapper } from 'layouts';
import React from 'react';
import Grev55Tab from 'views/Properties/Tabs/Grev55Tab';
import { useTheme } from '@mui/material/styles';
import { SEO as Head } from 'components/seo';

const Grev55TabPage = (): JSX.Element => {
  const theme = useTheme();
  const seo = {
    title: 'Explore GREV55 first Scandianavia’s digitized property.',
    description:
      'Discover our first property GREV55. Invest in real estate and diversify your portfolio effortlessly with fractional ownership of prime investment properties. ',
    pathname: '/properties/grev55',
  };

  return (
    <>
      <Head
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
      />
      <Main bgcolor={theme.palette.background.default}>
        <PropertiesWrapper>
          <Grev55Tab />
        </PropertiesWrapper>
      </Main>
    </>
  );
};

export default Grev55TabPage;
