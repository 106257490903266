import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { StyledPropsFix } from 'types';

export const TabTitle = styled(Typography)`
  font-family: Poppins SemiBold;
  font-size: 35px;
  margin: 0;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.1em;
  }
` as StyledPropsFix;
