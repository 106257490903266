import React from 'react';
import { SxProps, DefaultTheme } from '@mui/system';
import PrimaryText from '../PrimaryText';
import { useLocalise, useStringToArrayRows } from 'hooks/use-localise';
import { PrimaryTextProps } from '../PrimaryText/PrimaryText';

type Props = {
  sx?: SxProps<DefaultTheme>;
};

const disclaimer = {
  id: 4,
  anchor: '',
  content: ['disclaimer'],
};

const Disclaimer = ({ sx }: Props): JSX.Element => {
  // Establishing localise with viewname and sectionName
  const { localise } = useLocalise('Properties', 'AllTabs');

  const localisedDisclaimer = useStringToArrayRows(localise, disclaimer, {
    rowName: 'content',
  }) as PrimaryTextProps;

  return (
    <PrimaryText
      content={localisedDisclaimer}
      sx={{
        maxWidth: 'unset',
        paddingTop: '84px',
        textAlign: 'center',
        '.primaryText-infoText': { fontSize: '0.755rem' },
      }}
    />
  );
};

export default Disclaimer;
