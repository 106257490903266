import React from 'react';
import {
  GoogleMaps,
  KeyValueList,
  PrimaryText,
  SimpleList,
} from '../components';
import grevToken from 'images/Properties/grevToken.svg';
import { Box } from '@mui/system';
import Container from 'components/Container';
import { TabTitle } from './Styles';
import Disclaimer from '../components/Disclaimer';
import {
  useLocalise,
  useLocaliseKeyValueList,
  useRemapper,
  useStringToArrayRows,
} from 'hooks/use-localise';
import { KeyValueList as KeyValueListType } from '../components/KeyValueList/KeyValueList';
import { PrimaryTextProps } from '../components/PrimaryText/PrimaryText';
import { SimpleListProps } from '../components/SimpleList/SimpleList';
// import ThirdImage from 'images/Properties/market/03.webp';

const PrimaryTextContent = {
  aboutGrev55: {
    id: 1,
    title: 'aboutGrev55',
    anchor: 'about-grev55',
    content: ['aboutGrev55'],
  },
};

const SimpleListContent = {
  quickFacts: {
    id: 1,
    title: 'quickFacts',
    list: ['quickFacts'],
  },
  keyDistances: {
    id: 2,
    title: 'keyDistances',
    list: ['keyDistances'],
  },
};

const KeyValueListContent = {
  aboutToken: {
    id: 1,
    title: 'aboutToken',
    rows: [
      'aboutToken',
      {
        translationIndex: 0,
        translationOverride: ['', grevToken],
      },
    ],
  },
};

const Grev55Tab = (): JSX.Element => {
  // Establishing localise with viewname and sectionName
  const { localise } = useLocalise('Properties', 'Grev55');
  const remapper = useRemapper(localise);

  // Establishing localise keyValueList specific remapper
  const localiseKeyValueList = (
    sectionName: keyof typeof KeyValueListContent,
  ) =>
    useLocaliseKeyValueList(
      KeyValueListContent[sectionName] as KeyValueListType,
      remapper,
    );

  const localisedAboutGrev55 = useStringToArrayRows(
    localise,
    PrimaryTextContent['aboutGrev55'],
    { rowName: 'content' },
  ) as PrimaryTextProps;

  const localisedQuickFacts = useStringToArrayRows(
    localise,
    SimpleListContent['quickFacts'],
    { rowName: 'list' },
  ) as SimpleListProps;

  const localisedKeyDistances = useStringToArrayRows(
    localise,
    SimpleListContent['keyDistances'],
    { rowName: 'list' },
  ) as SimpleListProps;

  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'column', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <TabTitle>{localise('title')}</TabTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          gap: '52px',
        }}
      >
        <Box>
          <PrimaryText content={localisedAboutGrev55} />
        </Box>
        <Box>
          <KeyValueList content={localiseKeyValueList('aboutToken')} />
          <SimpleList content={localisedQuickFacts} />
          <SimpleList content={localisedKeyDistances} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GoogleMaps />
      </Box>
      <Disclaimer />
    </Container>
  );
};

export default Grev55Tab;
